'use strict';

/** Yext **/
var yext = {};
yext.n49PartnerId = 11053;
yext.baseUrl = 'https://pixel.yext-pub.com/plpixel';
yext.partnerId = '9HGlgc3xX8';
yext.targetMap = {
  'search-results': {
    'search-hit-contact-text': 'directions',
    'search-hit-business-name': 'name',
    'read-more': 'readmore',
    'search-hit-contact-phone': 'phone',
    'search-hit-image': 'photos'
  }
};
yext.clickTargetMap = {
  bios: 'bios',
  directions: 'directions',
  email: 'email',
  events: 'events',
  facebook: 'facebook',
  hours: 'hours',
  map: 'map',
  mappin: 'mappin',
  menu: 'menus',
  moreinfo: 'moreinfo',
  name: 'name',
  phone: 'phone',
  photos: 'photos',
  print: 'print',
  products: 'products',
  reviews: 'reviews',
  save: 'save',
  searchnearby: 'searchnearby',
  sendtophone: 'sendtophone',
  shareFacebook: 'share',
  shareTwitter: 'share',
  twitter: 'twitter',
  video: 'video',
  website: 'website'
};
yext.parameterMap = {
  pid: 'pid',
  ids: 'ids',
  source: 'source',
  action: 'action',
  query: 'query',
  queryLocation: 'queryLocation',
  rank: 'rank',
  target: 'target',
  property: 'property'
};
yext.impression = function(results, what, where) {
  var ids = getIds('yext', results);
  if(ids.toString().trim().length < 1) return false;
  var params = {
    pid: yext.partnerId,
    ids: ids,
    source: 'serp',
    action: 'impression',
    query: what,
    queryLocation: where,
    rank: getRank('yext', ids, results),
    property: 'n49.com'
  };
  var url = yext.baseUrl + objectToUrl(params);
  if(url.indexOf('undefined') !== -1) return false;
  return makeRequest(url);
};
yext.click = function(results, what, where, event) {
  var hitId = $(event.target).parents('.search-hit').attr('data-id');
  var ids = results[hitId]['partnerBid'] || '';
  if(ids.toString().trim().length < 1) return false;
  var params = {
    pid: yext.partnerId,
    ids: ids,
    source: 'serp',
    action: 'click',
    query: what,
    queryLocation: where,
    target: stringTargetFromEvent('yext', event),
    property: 'n49.com'
  }
  var url = yext.baseUrl + objectToUrl(params);
  if(url.indexOf('undefined') !== -1) return false;
  return makeRequest(url);
};
yext.track = function (params) {
  if (typeof(params) !== 'object') {
    params = {};
  }
  params.pid = yext.partnerId;
  params.ids = getIds('yext', [ params.business ]);
  if (params.ids.toString().trim().length < 1) {
    return false;
  }
  if (params.action == 'click') {
    if (params.target && yext.clickTargetMap[params.target]) {
      params.target = yext.clickTargetMap[params.target];
    } else {
      return false;
    }
  }
  params = mapParameterstoPartner(params, yext.parameterMap);
  var url = yext.baseUrl + objectToUrl(params);
  if (url.indexOf('undefined') !== -1) {
    return false;
  }
  return makeRequest(url);
}

/** UberAll **/
var uberAll = {};
uberAll.n49PartnerId = 11072;
uberAll.trackingId = 'Vdptl5s2knT3zugKgctcqBcYCaopllVVjVXuMxNTSEb6Q6J059VBQRz6WW5ZzsdP';
uberAll.baseUrl = 'https://trk.uberall.com/trk';
uberAll.targetMap = {
  'search-results': {
    'search-hit-contact-text': 'address',
    'search-hit-business-name': 'name',
    'read-more': 'moreLink',
    'search-hit-contact-phone': 'phone',
    'search-hit-image': 'photos'
  }
};
uberAll.clickTargetMap = {
  name: 'name',
  directions: 'address',
  phone: 'phone',
  email: 'email',
  fax: 'fax',
  hours: 'hours',
  website: 'website',
  photos: 'photos',
  video: 'video',
  facebook: 'facebook',
  twitter: 'twitter',
  linkedin: 'linkedin',
  shareFacebook: 'shareFacebook',
  shareTwitter: 'shareTwitter',
  map: 'map',
  specialoffer: 'specialoffer',
  products: 'products',
  menu: 'menu',
  events: 'events',
  reviews: 'reviews',
  checkin: 'checkin',
  save: 'save',
  moreinfo: 'moreLink'
};
uberAll.parameterMap = {
  pid: 'trkId',
  ids: 'ids',
  action: 'type',
  query: 'query',
  queryLocation: 'location',
  rank: 'position',
  target: 'clicked',
  property: 'portal'
};
uberAll.impression = function(results, what, where) {
  var ids = getIds('uberAll', results);
  if(ids.toString().trim().length < 1) return false;
  var params = {
    ids: ids,
    trkId: uberAll.trackingId,
    type: 'IMPRESSION',
    query: what,
    location: where,
    position: getRank('uberAll', ids, results),
    portal: 'n49.com'
  }
  var url = uberAll.baseUrl + objectToUrl(params);
  if(url.indexOf('undefined') !== -1) return false;
  return makeRequest(url);
};
uberAll.click = function(results, what, where, event) {
  var hitId = $(event.target).parents('.search-hit').attr('data-id');
  var ids = results[hitId]['objectID'];
  if(ids.toString().trim().length < 1) return false;
  var params = {
    ids: ids, // replace to partnerId in future
    trkId: uberAll.trackingId,
    type: 'CLICK',
    query: what,
    location: where,
    clicked: stringTargetFromEvent('uberAll', event),
    portal: 'n49.com'
  }
  var url = uberAll.baseUrl + objectToUrl(params);
  if(url.indexOf('undefined') !== -1) return false;
  return makeRequest(url);
};
uberAll.track = function (params) {
  if (typeof(params) !== 'object') {
    params = {};
  }
  params.pid = uberAll.trackingId;
  params.ids = getIds('uberAll', [ params.business ]);
  if (params.ids.toString().trim().length < 1) {
    return false;
  }
  if (params.action == 'click') {
    if (params.target && uberAll.clickTargetMap[params.target]) {
      params.target = uberAll.clickTargetMap[params.target];
    } else {
      return false;
    }
  }
  params = mapParameterstoPartner(params, uberAll.parameterMap);
  var url = uberAll.baseUrl + objectToUrl(params);
  if (url.indexOf('undefined') !== -1) {
    return false;
  }
  return makeRequest(url);
}

/**
 * Returns a concatenated list of ids to send to partners,
 * the list is build with fields which are dependant on partner param
 * for example Yext takes it's own Yext id,
 * whereas uberAll takes n49 id
 * @param {string} partner - Name of partner
 * @param {string} results - Object that Algolia returns after a search
 */
var getIds = function(partner, results) {
  var field;
  var out = '';
  var n49PartnerId;
  switch(partner) {
    case 'yext':
      field = 'partnerBid';
      n49PartnerId = yext.n49PartnerId;
    break;
    case 'uberAll':
      field = 'objectID';
      n49PartnerId = uberAll.n49PartnerId;
    break;
    default:
      throw new Error('Could not find partner');
    break;
  }
  if(!Array.isArray(results)) results = [];
  results.forEach(function(item, i) {
    // do not add the first comma
    if(item.partnerId && item.partnerId == n49PartnerId && item[field]) out += ((i !== 0) ? ',' : '') + item[field];
  });
  return out;
};

/**
 * Returns a concatenated list of rank positions of search results
 * rank result should be absolute. e.g. if page result is 2 and
 * a business is first in the result list the result would be 11
 * It's easy implementable since 'id' field on search results already
 * represents the rank
 * @param {string} results - Object that Algolia returns after a search
 */
var getRank = function(partner, trackableIds, results) {
  var field;
  var out = [];
  if(partner === 'yext') field = 'partnerBid';
  if(partner === 'uberAll') field = 'objectID';
  trackableIds.split(',').forEach(function(currentId) {
    var found = $.grep(results, function(element, index) {
      return Number(element[field]) === Number(currentId);
    });
    found[0] && out.push(Number(found[0].id) + 1);
  });
  return out.join(',');
};

var impression = function(results, what, where) {
  if(what === 'none') what = '';
  if(where === 'Planet Earth') where = '';
  yext.impression.apply(this, [results, what, where]);
  uberAll.impression.apply(this, [results, what, where]);
};

var click = function(results, what, where, event) {
  if(what === 'none') what = '';
  if(where === 'Planet Earth') where = '';
  yext.click.apply(this, [results, what, where, event]);
  uberAll.click.apply(this, [results, what, where, event]);
};

var track = function (params) {
  yext.track.apply(this, [ params ]);
  uberAll.track.apply(this, [ params ]);
}

/**
 * Returns a string Url path from any key-value object
 * Works like this {key1:val1, key2:val2} -> ?key1=val1&key2=val2
 * @param {string} object - can be any object
 */
var objectToUrl = function(object) {
  var out = '';
  for(var key in object) {
    out += '&' + key + '=' + object[key];
  }
  // replace the first occurance of & to ?
  // for the path to be attachable to API root url
  return out.replace('&', '?');
}

/**
 * Creates an image and sets the source of it
 * @param {string} url - image will have this source
 */
var makeRequest = function(url) {
  var img = document.createElement('img');
  img.src = url;
};

/**
 * Returns a class<->target map for specified partner
 * @param {string} partner - conventional name of the partner
 */
var getTargetMap = function(partner) {
  var map;
  switch(partner) {
    case 'yext':
      map = yext.targetMap;
    break;
    case 'uberAll':
      map = uberAll.targetMap;
    break;
    default:
      throw new Error('Could not find partner ' + partner);
    break;
  }
  return map;
};

/**
 * Converts a class of a clicked element
 * to a string representation of event target for clients
 * e.g. clicking on <div class="search-hit-contact-text"></div>
 * will result into mapping the event target into 'contact'
 * @param {event} event - bubbled event object
 */
var stringTargetFromEvent = function(partner, event) {
  var map = getTargetMap(partner);
  var topLevelClasses = $(event.delegateTarget).attr('class');
  var targetClasses = $(event.target).attr('class'); // actual target
  var finalTarget;
  // check top level delegatees
  for(var delegatee in map) {
    if(topLevelClasses.indexOf(delegatee) !== -1) {
      for(var targetClass in map[delegatee]) {
        // find needed element here
        if(targetClasses.indexOf(targetClass) !== -1) {
          finalTarget = map[delegatee][targetClass];
          break;
        }
      }
    }
  }
  if(!finalTarget) throw new Error('Could not map element with the target');
  return finalTarget;
};

function mapParameterstoPartner(params, map) {
  var result = {};
  var keys = Object.keys(params);
  for (var i = 0; i < keys.length; i++) {
    if (map[keys[i]]) {
      result[map[keys[i]]] = params[keys[i]];
    }
  }
  return result;
}

module.exports = {
  impression: impression,
  click: click,
  track: track
};
